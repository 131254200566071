import axios from '@axios'

export default {
    namespaced: true,
    state: {
        cartItems: [],
        cartItemsCount: 0,
    },
    getters: {
        getCartItems: state => state.cartItems,
        getCartItemsCount: state => state.cartItemsCount,
    },
    mutations: {
        UPDATE_CART_ITEMS_COUNT(state, count) {
            state.cartItemsCount = count
        },
        ADD_ITEMS_TO_CART(state, items) {
            state.cartItems = items
        },
        ADD_TO_CART(state, product) {
            state.cartItems.push(product)
        },
        REMOVE_FROM_CART(state, product) {
            const productIndex = state.cartItems.findIndex(p => p.id === product.id)
            state.cartItems.splice(productIndex, 1)
        },
    },
    actions: {
        fetchCartItems({dispatch, commit}) {
            console.log('fetching..')
            dispatch('fetchCartProducts')
                .then(response => {
                    commit('ADD_ITEMS_TO_CART', response.data.items)
                    commit('UPDATE_CART_ITEMS_COUNT', response.data.items.length)
                }).catch(error => {
                dispatch('refreshCart')
                dispatch('fetchCartProducts')
                    .then(response => {
                        commit('ADD_ITEMS_TO_CART', response.data.items)
                        commit('UPDATE_CART_ITEMS_COUNT', response.data.items.length)
                    })
            })
        },
        refreshCart() {
            axios
                .post('api/cart/initialize')
                .then(response => {
                    const {token} = response.data
                    localStorage.setItem('cartToken', token)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getCartToken() {
            let token = localStorage.getItem('cartToken')
            if (!token) {
                axios
                    .post('api/cart/initialize')
                    .then(response => {
                        token = response.data.token
                        localStorage.setItem('cartToken', token)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        fetchProducts(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('api/courses', {params: payload})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchProduct(ctx, {slug}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`api/course/${slug}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCartProducts() {
            const token = localStorage.getItem('cartToken')

            return new Promise((resolve, reject) => {
                axios
                    .get(`api/cart/${token}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addProductInCart(ctx, {productId, chosenSubscription = null, voucher_price = null}) {
            const token = localStorage.getItem('cartToken')
            console.log('3: ');console.log(voucher_price);
            return new Promise((resolve, reject) => {
                axios
                    .post(`api/cart/${token}/addToCart`, {
                        course_id: productId,
                        subscription_type: chosenSubscription,
                        voucher_price: voucher_price,
                        quantity: 1,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDiscountToCart(ctx, {code}) {
            const token = localStorage.getItem('cartToken')

            return new Promise((resolve, reject) => {
                axios
                    .post(`api/cart/${token}/addDiscountCode`, {
                        code,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeProductFromCart(ctx, {productId}) {
            const token = localStorage.getItem('cartToken')

            return new Promise((resolve, reject) => {
                axios
                    .post(`api/cart/${token}/removeFromCart`, {
                        item_id: productId,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addAddressInCart(ctx, {address, reg4}) {
            const token = localStorage.getItem('cartToken')

            return new Promise((resolve, reject) => {
                axios
                    .post(`api/order/${token}/makeOrder`, {
                        email: address.email,
                        password: address.password,
                        first_name: address.first_name,
                        last_name: address.last_name,
                        address: address.address,
                        address_2: address.address_2,
                        post_code: address.post_code,
                        city: address.city,
                        country: address.country,
                        is_company: address.is_company,
                        company_name: address.company_name,
                        company_address: address.company_address,
                        company_post_code: address.company_post_code,
                        company_city: address.company_city,
                        company_country: address.company_country,
                        is_invoice: address.is_invoice,
                        nip: address.nip,
                        comments: address.comments,
                        exam_year: address.exam_year,
                        accept_newsletter: reg4,
                    })
                    .then(response => resolve(response))
                    .catch(response => reject(response))
            })
        },
    },
}
